// Feature Names for the feature flags
export const Features = {
  ACADEMY_UI_REFRESH: 'fflag.academy.uiRefresh',
  AI_CHATBOT: 'fflag.ai.chatbot',
  COMPETITIVE_INTELLIGENCE_CREATION_FLOW_UPDATES:
    'fflag.competitiveIntelligence.creationFlowUpdates',
  COMPETITIVE_INTELLIGENCE_AD_CAMPAIGNS_OPPORTUNITIES:
    'fflag.competitiveIntelligence.adCampaignsOpportunities',
  COMPETITIVE_INTELLIGENCE_PRODUCT_SLIDEOUT_RANK_PERFORMANCE:
    'fflag.competitiveIntelligence.slideout.rankPerformance',
  COMPETITIVE_INTELLIGENCE_RANK_CHANGE_DISTRIBUTION:
    'fflag.competitiveIntelligence.keywordsChangeDistribution',
  COMPETITIVE_INTELLIGENCE_AD_OPPORTUNITIES:
    'fflag.competitiveIntelligence.adOpportunities',
  COMPETITIVE_INTELLIGENCE_UPGRADE_INCENTIVE:
    'fflag.competitiveIntelligence.upgradeIncentive',
  COMPETITIVE_INTELLIGENCE_VALIDATION:
    'fflag.competitiveIntelligence.validation',
  KEYWORDS_SHARE_OF_VOICE: 'fflag.keywords.shareOfVoice',
  KEYWORDS_UI_REFRESH: 'fflag.keywords.uiRefresh',
  KEYWORD_SCOUT_DISTRIBUTION: 'fflag.keywords.keywordsDistribution',
  KEYWORD_SCOUT_BADGE_COLUMN: 'fflag.keywords.ksBadgeColumn',
  KEYWORD_SCOUT_AD_CAMPAIGNS_COLUMN: 'fflag.keywords.kwsAdCampaignsColumn',
  MONEY_BACK_GUARANTEE: 'fflag.account.moneyBackGuarantee',
  OPPORTUNITY_FINDER_TABLE_SPARKLINE: 'fflag.opportunityFinder.sparkline',
  SELLER_FEATURES_DISABLED: 'fflag.sellerFeatures.disabled',
  SELLER_FEATURES_MAINTENANCE: 'fflag.sellerFeatures.maintenance',
  LISTING_BUILDER_DISABLE_SYNC: 'fflag.listingBuilder.disableSync',
  LISTING_GRADER_DEPRECATION: 'fflag.listingGrader.deprecation',
  PPC_UPDATE_BANNERS: 'fflag.account.ppcUpdatePermissionsBanner'
}
